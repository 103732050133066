import axios from "axios";

const service = axios.create({
    baseURL: '/',
    timeout: 20000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        const res = response.data;

        // if (res.code !== 0) {
        //     console.log('error', res);
            
        //     return Promise.reject(new Error(res.msg || 'Error'));
        // }

        return res;
    },
    error => {
        console.log('error', + error);
        return Promise.reject(error);
    }
);

export default service;