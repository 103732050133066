import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home/Index";

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/weibo" element={<Home />} />
      <Route path="/zhihu" element={<Home />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;