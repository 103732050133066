import request from '../utils/request';

export function getToutiao() {
    return request({
        url: '/api/toutiao/',
        method: 'get'
    });
}

export function getWeiboResou() {
    return request({
        url: '/api/webo_resou/',
        method: 'get'
    });
}

export function getBaidu() {
    return request({
        url: '/api/baidu/',
        method: 'get'
    });
}

export function getZhihu() {
    return request({
        url: '/api/zhihu/',
        method: 'get'
    });
}
