import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toutiaoHandle, weiboDataHandle, zhihuDataHandle } from '../utils/dataHandle';
import { ListItemType } from '../utils/types';
import './index.scss';

export default function Home() {
  let [currentIndex, setCurrentIndex] = useState(0);
  let [currentData, setCurrentData] = useState<ListItemType[]>();
  interface TabIndexType {
    '/': number,
    '/weibo': number,
    '/zhihu': number,
    [propName: string]: number
  }

  const TabIndex: TabIndexType = {
    '/': 0,
    '/weibo': 1,
    '/zhihu': 2,
  }

  const tabsMap = [
    {
      text: '头条热榜',
      route: '/'
    },
    {
      text: '微博热搜',
      route: '/weibo'
    },
    {
      text: '知乎热榜',
      route: '/zhihu'
    },
  ]

  const routeData = useLocation();
  useEffect(() => {
    const pathIndex = TabIndex[routeData.pathname];
    setCurrentIndex(pathIndex);

    sendRequest(pathIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeTab(index: number) {
    if (index === currentIndex) return;

    setCurrentIndex(index);
    setCurrentData([]);
    sendRequest(index);
  }

  async function sendRequest(tabIndex: number) {
    console.log('start...', tabIndex);
    
    switch (tabIndex) {
      case 0:
        console.log('请求头条热搜');
        const dataToutiao = await toutiaoHandle();
        setCurrentData(dataToutiao);
        break;
      case 1:
        console.log('请求微博热搜');
        const dataWeibo = await weiboDataHandle();
        setCurrentData(dataWeibo);
        break;
      case 2:
        const dataZhihu = await zhihuDataHandle();
        setCurrentData(dataZhihu);
        break;
      case 3:
        break;
      default:
        break;
    }
    
    
  }

  return (
    <div className="home-box">
      <div className="tab-box">
        {
          tabsMap.map((item, index) => <div onClick={() => changeTab(index)} className={'tab-item ' + (currentIndex === index ? 'tab-active': '')} key={index}>
            <Link className='link' to={item.route}>{ item.text }</Link>
          </div>)
        }
      </div>

      <div style={{width: '100%', height: '55px'}}></div>

      {
        currentData?.map((item: ListItemType, index) => <div className='item-box' key={index}>
          <div className="content-box"><a target='_blank' href={item.detailUrl} rel="noreferrer">{ index + 1 }. { item.content }</a></div>
          <div className="hot-value-box">{ item.hotValue }</div>
          </div>)
      }
    </div>
  );
}