import * as Cheerio from 'cheerio';
import { getToutiao, getWeiboResou, getZhihu } from '../api/home';
import { ListItemType } from "./types";

export async function toutiaoHandle() {
    const res = await getToutiao();

    let result: ListItemType[] = [];

    for (let index = 0; index < res.data.length; index++) {
        let tempMap: ListItemType = {
            content: res.data[index].QueryWord,
            hotValue: res.data[index].HotValue??'',
            detailUrl: `https://so.toutiao.com/search?keyword=%23${ res.data[index].QueryWord }%23`
        } 
        result.push(tempMap);
    }

    return result;
}

export async function weiboDataHandle() {
    const res = await getWeiboResou();

    const resList = res.data.cards[0].card_group;

    let result: ListItemType[] = [];

    for (let index = 0; index < resList.length; index++) {
        let tempMap: ListItemType = {
            content: resList[index].desc,
            hotValue: resList[index].desc_extr??'',
            detailUrl: `https://m.weibo.cn/search?containerid=100103type%3D1%26t%3D10%26q%3D%23${resList[index].desc}%23`
        } 
        result.push(tempMap);
    }

    return result;
}

export async function zhihuDataHandle_del() {
    const res = await getZhihu();

    const $ = Cheerio.load(res.toString());
    const itemContent = $('#js-initialData').html()??'';
    const resList =  JSON.parse(itemContent).initialState.topstory.hotList;

    let result: ListItemType[] = [];

    for (let index = 0; index < resList.length; index++) {
        let tempMap: ListItemType = {
            content: resList[index].target.titleArea.text,
            hotValue:  resList[index].target.metricsArea.text,
            detailUrl: resList[index].target.link.url
        } 
        result.push(tempMap);
    }

    return result;
    
}

export async function zhihuDataHandle() {
    const res = await getZhihu();

    const resList =  res.data;

    let result: ListItemType[] = [];

    for (let index = 0; index < resList.length; index++) {
        let tempMap: ListItemType = {
            content: resList[index].target.title,
            hotValue:  resList[index].detail_text,
            detailUrl: `https://www.zhihu.com/question/${resList[index].target.id}`
        } 
        result.push(tempMap);
    }

    return result;
    
}